<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Export der Veranstaltungen">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="regionChanged"
        ></region-chooser>
      </template>
      <el-card>
        <div slot="header">
          <span
            >Exportieren Sie Veranstaltungen im ausgewählten Zeitraum (für
            Nutzung in Excel o.ä.)</span
          >
        </div>
        <div>
          <el-date-picker
            v-model="dateRange"
            :picker-options="pickerOptions"
            :clearable="false"
            type="daterange"
            range-separator="–"
            start-placeholder="Start"
            end-placeholder="Ende"
            format="dd.MM.yyyy"
            size="large"
          >
          </el-date-picker>
          <div style="margin-top: 24px;">
            <el-button :loading="loading" type="primary" @click="download"
              >Download der CSV-Datei</el-button
            >
          </div>
        </div>
      </el-card>
    </page-content>
  </div>
</template>

<script>
import { format, startOfYear, endOfYear, addYears } from "date-fns"
import fileDownload from "js-file-download"
import Repository from "@/repositories/api.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"

export default {
  metaInfo: {
    title: "Export Veranstaltungen"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser
  },
  data() {
    return {
      loading: false,
      format,
      dateRange: "",
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Letzter Monat",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "Letzte 3 Monate",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "Letzte 6 Monate",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "Letzte 12 Monate",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "Letztes Kalenderjahr",
            onClick(picker) {
              const end = endOfYear(addYears(new Date(), -1))
              const start = startOfYear(addYears(new Date(), -1))
              picker.$emit("pick", [start, end])
            }
          }
        ]
      }
    }
  },
  computed: {},
  async created() {
    const end = endOfYear(new Date())
    const start = startOfYear(new Date())
    this.dateRange = [start, end]
  },
  methods: {
    regionChanged() {
      //
    },
    async download() {
      this.loading = true
      let response = await Repository.get("/admin/events/export", {
        params: {
          region_id: this.$store.state.auth.chosenRegion,
          start: format(this.dateRange[0], "yyyy-LL-dd"),
          end: format(this.dateRange[1], "yyyy-LL-dd")
        }
      })
      this.loading = false
      fileDownload(
        response.data,
        `veranstaltungen_${format(
          this.dateRange[0],
          "yyyy-LL-dd"
        )}_bis_${format(this.dateRange[1], "yyyy-LL-dd")}.csv`
      )
    }
  }
}
</script>
